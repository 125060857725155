<template>
    <div>
      <b-card>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col cols="12" md="3">
              <b-form-group label="Username" label-for="name" class="mb-2">
                <b-form-input
                  id="name"
                  v-model="search.name"
                  class="form-control"
                  placeholder="Please Enter Username"
                />
              </b-form-group>
            </b-col>
            
            <!-- from Date Filter -->
            <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
              <b-form-group :label="$t('From date')" label-for="fromDate">
                <flat-pickr
                  id="fromDate"
                  v-model="search.fromDate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
              <b-form-group :label="$t('To date')" label-for="toDate">
                <flat-pickr
                  id="toDate"
                  v-model="search.toDate"
                  class="form-control"
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
            </b-col>

            <!-- md="4" -->
            <b-col cols="12" class="d-flex align-items-end justify-content-end">
              <b-button variant="outline-primary" @click="resetFilter()">
                Reset
              </b-button>
              <b-button variant="primary" class="ml-2" @click="searchFilter()">
                Search
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card no-body class="mb-0">
        <b-table
          ref="refUserNewDepositListTable"
          class="position-relative table-white-space"
          :items="userNewDepositList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
  
          <template #cell(username)="data">
            <div v-if="data.item.user" class="cursor-pointer text-white">
              {{ data.item.user.username }}
            </div>
          </template>
  
          <template #cell(agency_username)="data">
            <div v-if="data.item.agency" class="cursor-pointer text-white">
              {{ data.item.agency.username }}
            </div>
          </template>
        </b-table>
  
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalNewUserDeposit"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BFormGroup,
    VBTooltip,
  } from "bootstrap-vue";
  import store from "@/store";
  import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
  import { numberFormat, formatDateTime } from "@core/utils/filter";
  import useAgencyRadioList from "@/views/marketing/useAgencyRadioList";
  import marketingStoreModule from "@/views/marketing/marketingStoreModule";
  import baseUrl from "@/libs/base-url";
  import i18n from "@/libs/i18n";
  import flatPickr from "vue-flatpickr-component";
  
  export default {
    components: {
      // AddAgencyRadioModal: () => import("./AddAgencyRadioModal.vue"),
      // UpdateAgencyRadioModal: () => import('./UpdateAgencyRadioModal.vue'),
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BPagination,
      BFormGroup,
      flatPickr
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    data() {
      return {
        agencyRadioDetail: null,
      };
    },
    methods: {},
    setup() {
      const MARKETING_MODULE_NAME = "marketing";
      if (!store.hasModule(MARKETING_MODULE_NAME))
        store.registerModule(MARKETING_MODULE_NAME, marketingStoreModule);
      onUnmounted(() => {
        if (store.hasModule(MARKETING_MODULE_NAME))
          store.unregisterModule(MARKETING_MODULE_NAME);
      });

      const refUserNewDepositListTable = ref(null);
      const userNewDepositList = ref([]);

      const tableColumns = [
        { key: "index", label: "#" },
        { key: "username", label: "Username" },
        { key: "agency_username", label: "Agency username" },
        { key: "date", label: "Date" },
      ];
      const perPage = ref(25);
      const totalNewUserDeposit = ref(0);
      const currentPage = ref(1);
      const search = ref({
        name: "",
        toDate: "",
        fromDate: "",
      });

      const refetchData = () => {
        refUserNewDepositListTable.value.refresh();
      };

      watch([currentPage], () => {
        refetchData();
        getUserNewUserDeposit();
      });

      const getUserNewUserDeposit = (ctx, callback) => {
        store
          .dispatch("marketing/fetchUserNewDepositReport", {
            pageNumber: currentPage.value,   
            username: search.value.name,
            toDate: search.value.toDate,
            fromDate: search.value.fromDate, 
          })
          .then((response) => {
            if (response.data.code == "00") {
              userNewDepositList.value = response.data.data;
              totalNewUserDeposit.value = response.data.count;
              perPage.value =
                currentPage.value == 1 ? response.data.data.length : 25;
            }
          });
      };

      getUserNewUserDeposit();

      const dataMeta = computed(() => {
        const localItemsCount = refUserNewDepositListTable.value
          ? refUserNewDepositListTable.value.localItems.length
          : 0;
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalNewUserDeposit.value,
        };
      });

      const resetFilter = () => {
        search.value.name = "";
        search.value.fromDate = "";
        search.value.toDate = "";
        getUserNewUserDeposit();
      };

      const searchFilter = () => {
        getUserNewUserDeposit();
      };
  
      return {
        tableColumns,
        perPage,
        currentPage,
        totalNewUserDeposit,
        dataMeta,
        refUserNewDepositListTable,
        refetchData,
        numberFormat,
        formatDateTime,
        baseUrl,
        userNewDepositList,
        search,
        searchFilter,
        resetFilter
      };
    },
  };
  </script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>